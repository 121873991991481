html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: inherit;
}

*:focus {
    outline: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #fafafc;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* IE: support <main> element */
main {
    display: block;
}

/* Chrome: render better images */
img {
    image-rendering: -webkit-optimize-contrast;
}

.App,
#root {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

@supports (height: env(safe-area-inset-top)) {
    @media (max-device-width: 600px) {
        iframe#webWidget {
            padding-top: env(safe-area-inset-top);
            padding-bottom: env(safe-area-inset-bottom);
        }
    }
}

@media print {
    .print-hide {
        display: none !important;
    }
    ,
    /* Print Normalization Class */
    .published-speaker {
        position: relative !important;
        top: 0 !important;
    }
    ,
    .App,
    #root {
        position: relative;
        display: block;
        height: auto;
        width: auto;
        overflow: visible;
        overflow-y: visible;
    }
    .tab-content,
    .tab-wrapper,
    .floating-tab {
        position: relative !important;
        box-shadow: unset !important;
        display: block;
        height: auto !important;
        width: auto !important;
        top: unset !important;
        bottom: unset !important;
        overflow: visible !important;
        overflow-y: visible !important;
    }
    .print-transcript-wrapper {
        padding-bottom: 600px;
    }
}
