/* RUBIK FONTS */

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./Rubik-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Rubik-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("./Rubik-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./Rubik-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Rubik-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./Rubik-Black.ttf") format("truetype"); }

/* CharisSIL FONTS */

@font-face {
    font-family: 'CharisSIL';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./CharisSIL-R.ttf") format("truetype");
}

@font-face {
    font-family: 'CharisSIL';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("./CharisSIL-I.ttf") format("truetype");
}

@font-face {
    font-family: 'CharisSIL';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("./CharisSIL-B.ttf") format("truetype");
}

@font-face {
    font-family: 'CharisSIL';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("./CharisSIL-BI.ttf") format("truetype");
}
